import s1 from './img/juridica.jpg'
import s2 from './img/psicologica.jpg'
import s3 from './img/contable.jpg'
import s4 from './img/empresarial.jpg'
import s5 from './img/financiera.jpg'
import s6 from './img/digital.jpg'
import s7 from './img/patrimonial.jpg'
import s8 from './img/pdigital.jpg'
import s9 from './img/vehicular.jpg'
import s10 from './img/rs.jpg'
import s11 from './img/medica.jpg'
import s12 from './img/vial.jpg'

import g1 from './img/g1.jpg'
import g2 from './img/g2.jpg'
import g3 from './img/g3.jpg'
import g4 from './img/g4.jpg'
import g5 from './img/g5.jpg'
import g6 from './img/g6.jpg'

export const gallery = [ g1, g2, g3, g4, g5, g6 ]

export const services = [
    {
      title: 'Asesoría Juridica',
      desc: 'Tramites en ayuntamientos y permisos (Desarrollo urbano, ecología, protección civil, desarrollo económico, etc.)',
      image: s1
    },
    {
      title: 'Asistencia Psicológica',
      desc: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec mattis, pulvinar dapibus leo.',
      image: s2
    },
    {
      title: 'Asesoría Contable',
      desc: 'Alta ante registro federal de contribuyentes • Obtención de FIEL y clave CIEC (contraseña) • Revisión y determinación de contribuciones federales y estatales • Cumplimiento de obligaciones fiscales • Declaraciones informativas de operaciones de terceros • Elaboración de registros contables, estados financieros y contabilidad financiera',
      image: s3
    },
    {
      title: 'Gestión Empresarial',
      desc: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec mattis, pulvinar dapibus leo.',
      image: s4
    },
    {
      title: 'Asesoría Financiera',
      desc: 'Pensiones, trámites ante el imss, infonavit, etc.',
      image: s5
    },
    {
      title: 'Marketing Digital',
      desc: 'Te ayudamos a llevar tu negocio al mundo digital, desde creación de sitios web, aplicaciones, diseño digital, gestión de redes sociales, producción de fotografías o videos y más.',
      image: s6
    },
    {
      title: 'Asesoría Patrimonial',
      desc: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec mattis, pulvinar dapibus leo.',
      image: s7
    },
    {
      title: 'Publicidad Digital',
      desc: 'Creamos campañas a través de los principales medios digitales, ya sea para llevar nuevos clientes a tu negocio, posicionamiento de marca, llenado de formularios, etc.',
      image: s8
    },
    {
      title: 'Trámites Vehiculares',
      desc: 'Alta y baja de placas, permisos para circular, cambio de propietario, reemplacamiento, reposición de tarjeta de circulación, cambio de entidad',
      image: s9
    },
    {
      title: 'Gestión de Redes Sociales',
      desc: 'Nos encargamos de administrar tus redes sociales por ti, creación y diseño de contenido, desarrollo de estrategia, respuestas a comentarios y/o mensajes, campañas, etc.',
      image: s10
    },
    {
      title: 'Asistencia Médica',
      desc: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec mattis, pulvinar dapibus leo.',
      image: s11
    },
    {
      title: 'Asistencia Víal',
      desc: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec mattis, pulvinar dapibus leo.',
      image: s12
    }
  ]