import { useState, useEffect, useRef } from 'react';
import { services, gallery } from './services';

import axios from 'axios'
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './Sample.css';

import { WrenchAdjustable, BoxSeam, Headset, Basket, Book, People, EnvelopeFill, Whatsapp, TelephoneFill, Facebook, GeoAltFill, Instagram } from 'react-bootstrap-icons';

import logo_start from './img/logo.png'
import logo from './img/logo.png'
import homeTitle from './img/slogan.png'
import us from './img/somos.png'

import pdf1 from './img/ranking/ONGS-CODHEM-1.jpg'
import pdf2 from './img/ranking/ONGS-CODHEM-2.jpg'
import pdf3 from './img/ranking/ONGS-CODHEM-3.jpg'
import pdf4 from './img/ranking/ONGS-CODHEM-4.jpg'
import pdf5 from './img/ranking/ONGS-CODHEM-5.jpg'
import pdf6 from './img/ranking/ONGS-CODHEM-6.jpg'
import pdf7 from './img/ranking/ONGS-CODHEM-7.jpg'
import pdf8 from './img/ranking/ONGS-CODHEM-8.jpg'
import pdf9 from './img/ranking/ONGS-CODHEM-9.jpg'

import pdfFile from './ONGS-CODHEM.pdf';

const API_PATH = '';
const SITE_KEY = '';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

function App() {
  const images = { logo, logo_start }
  const pdf = [ pdf1,pdf2,pdf3,pdf4,pdf5,pdf6,pdf7,pdf8,pdf9 ]
  const [ image, setImage ] = useState(images['logo_start'])

  const refHome = useRef(null);
  const refUs = useRef(null);
  const refProducts = useRef(null);
  const refServices = useRef(null);
  const refContact = useRef(null);

  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [ activeForm, setActiveForm ] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  const [ faceLink, setFaceLink ] = useState('https://www.facebook.com/VanguardiaDH');
  const [ instaLink, setInstaLink ] = useState('https://www.instagram.com/vanguardiadh');

  const [ goingUp, setGoingUp ] = useState(false);
  const prevScrollY = useRef(0);

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  useEffect(() => {
    
    /*if ( isMobile ) {
      if( isAndroid )
        setFaceLink('fb://page/111933257823824')
      else if( isIOS )
        setFaceLink('fb://profile/111933257823824')
    }*/

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;

      if(currentScrollY > 10) {
        document.getElementById("rensa-navbar").className = "nav-bar-custom navbar navbar-expand-lg fixed-top";
        document.getElementById("brand_image").className = "d-inline-block align-top brand-image";
        setImage(images['logo'])

        var navs = document.getElementsByClassName('nav-link-custom');
        for(var i = 0; i < navs.length; i++) {
          navs[i].style.color = '#000';
        }

        var icons = document.getElementsByClassName('nav-icons');
        for(var i = 0; i < icons.length; i++) {
          icons[i].style.color = '#000';
        }
      } else {
        document.getElementById("rensa-navbar").className = "nav-bar-custom-start navbar navbar-expand-lg navbar-dark fixed-top";
        document.getElementById("brand_image").className = "d-inline-block align-top brand-image-start";
        setImage(images['logo_start'])

        var navs = document.getElementsByClassName('nav-link-custom');
        for(var i = 0; i < navs.length; i++) {
          navs[i].style.color = isMobile ? '#000' : '#fff';
        }

        var icons = document.getElementsByClassName('nav-icons');
        for(var i = 0; i < icons.length; i++) {
          icons[i].style.color = isMobile ? '#000' : '#fff';
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const handleNavClick = (eventKey) => {
    const yOffset = -100; 
    const element = eval(eventKey).current;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
  }

  const handleOnClick = (e) => {
    e.preventDefault();

    setTitle("Correo guardado")
    setMessage("Gracias por tu interes, en breve nos comunicaremos contigo. Gracias.");
    setShowMessage(true)
    /*setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        handleOnSubmit(token);
      });
    });*/
  }

  const handleOnSubmit = (token) => {
    var form = document.getElementById('contactForm');
    var data = new FormData(form);
    data.append('recaptcha_response', token);

    axios.post(API_PATH, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then(function(response) {

      if(response.data == "FAIL" || response.data == "ERROR") {
        setLoading(false)
        if(isMobile) setActiveForm(false)
        setTitle("Error")
        setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
        setShowMessage(true)
      } else {
        setLoading(false)
        if(isMobile) setActiveForm(false)
        setTitle("Mensaje enviado")
        setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
        setShowMessage(true)

        setName("");
        setPhone("");
        setEmail("");
        setComments("");
        setShow(false);
      }

    }).catch(function(err) {
      setLoading(false)
      setTitle("Error")
      setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
      setShowMessage(true)
    });
  }

  const handleCloseMessage = () => setShowMessage(false);

  return (
    <div className="App">
      <Modal show={showMessage} onHide={handleCloseMessage}>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={handleCloseMessage}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Navbar id="rensa-navbar" collapseOnSelect expand="lg" variant="dark" className="nav-bar-custom-start" fixed="top">
        <Container>
          <Navbar.Brand href="#home">
            <img alt="" id="brand_image" src={ image } height="auto" width="130px" className="d-inline-block align-top brand-image-start" onClick={() => handleNavClick("refHome")}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            <Nav onSelect={handleNavClick}>
              <Nav.Link eventKey="refUs">
                <div className="nav-link-custom">Nuestra misión</div>
              </Nav.Link>
              <Nav.Link eventKey="refProducts">
                <div className="nav-link-custom">Nuestra historia</div>
              </Nav.Link>
              <Nav.Link eventKey="refServices">
                <div className="nav-link-custom">Nuestros servicios</div>
              </Nav.Link>
              <Nav.Link eventKey="refContact">
                <div className="nav-link-custom">Ranking</div>
              </Nav.Link>

              <Nav.Link className="nav-link-custom nav-link-icons" onClick={ () =>  window.open(faceLink) }>
                <Facebook className="nav-icons" onClick={ () =>  window.open(faceLink) } />&nbsp;&nbsp;&nbsp;
                <Instagram className="nav-icons" onClick={ () =>  window.open(instaLink) } />&nbsp;&nbsp;
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="banner-bg" ref={ refHome }>
        <Container>
          <Row>
            <Col md={ 8 }>
              <img alt="" src={ homeTitle } className="home-img" />
            </Col>
            <Col md={ 4 }>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section-a" ref={ refUs }>
        <Container>
          <Row className="align-items-center">
            <Col md={ 6 } xs={ 12 }>
              <img src={ us } className="us-img"/>
            </Col>
            <Col md={ 6 } xs={ 12 }>
              <div className={ isMobile ? "secction-titles align-center p-30" : "secction-titles align-left p-30" }>
                NUESTRA MISIÓN
              </div>
              <div className="grl-text p-30">
              Contribuir al mejoramiento de la calidad de vida de los individuos y al establecimiento de proyectos y modelos de vida en lo individual y grupal para que trasciendan al mejoramiento de la calidad de vida de la sociedad, mediante atención integral en varios centros establecidos a personas y sectores de escasos recursos, mediante la atención psicológica, medica, orientación social, educación y capacitación laboral así como asistencia y asesoría jurídica. Cooperar dentro de las posibilidades de esta asociación, con las personas y con las instituciones políticas y privadas que desarrollen actividades de carácter educativo, deportivo, cultural o cualquier otra actividad que nos ayuden a la difusión de los derechos humanos. Cooperar con las dependencias del gobierno Mexicano en los trabajos de promoción y difusión de educación social y de derechos humanos.
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section-b" ref={ refProducts }>
        <Container>
          <Row>
            <Col md={ 6 }>
              <div className={ isMobile ? "secction-titles align-center p-30 c-white" : "secction-titles align-left ph-30 c-white" }>
                NUESTRA HISTORIA
              </div>
              <div className={ isMobile ? "grl-text p-30 c-white" : "grl-text ph-30 c-white" }>
                Vanguardia en la Defensa de los Derechos Humanos fue fundada en el 2009, por nuestro presidente fundador FILIBERTO ARENAS OLIVARES quien tenía la visión de ayudar a las personas de escasos recursos y desprotegidas de todas las entidades federativas en todo México. Se inicia con una oficina en Tultitlan, dándole oportunidad a la gente más vulnerable de darle voz con las autoridades correspondientes, así se comienza con la labor social y poco a poco fue sumándose más gente a este proyecto haciendo de esta una asociación más firme y sólida, haciéndola expandir a mas municipios y más estados de la república. En el 2011 se firma un convenio de tripartita con la CODHEM y la CNDH así se refuerza el compromiso de las autoridades estatales y federales a sumarse con VANGUARDIA EN LA DEFENSA DE LOS DERECHOS HUMANOS, reafirmándolo una vez más en el 2014 con la actualización de dicho convenio actualmente vigente. En el 2015 se propone a VANGUARDIA EN LA DEFENSA DE LOS DERECHOS HUMANOS como intermediario entre las autoridades militares y la sociedad civil para poder acercar ambas partes y llegando así a ser el enlace entre ambos.​
              </div>
            </Col>
            <Col md={ 6 }>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section-c" ref={ refServices }>
        <Container className="services-padding">
          <div className="secction-titles align-center">
            NUESTROS SERVICIOS
          </div>
          <br/>
          <Row>
            { services.map((item, index) => (
              <Col md={ 4 } xs={ 12 } className="col-mar12" key={ 's-' + index }>
                <Card>
                  <Card.Img variant="top" src={ item.image } />
                  <Card.Body>
                    <Card.Title>{ item.title }</Card.Title>
                    <Card.Text>
                      { item.desc }
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            )) }
          </Row>
        </Container>
        
        <br/><br/><br/>
        <Container>
          <div className="secction-titles align-center">
            NUESTRAS ACCIONES
          </div>
          <br/>
          <Row>
              { gallery.map((item, index) => (
                <Col md={ 4 } xs={ 12 } className="col-mar12" key={ 'g-' + index }>
                    <img src={ item } className="gallery-style"/>
                </Col>
              )) }
          </Row>
        </Container>
      </div>

      <div className="section-d" ref={ refContact }>
        <Container>
          <Row>
            <div className={ isMobile ? "secction-titles align-center c-white" : "secction-titles align-left c-white" } style={{ paddingBottom: '35px' }}>
              RANKING
            </div>

            <div className="" align="center">
              {/*<Document file={ pdfFile } onLoadSuccess={onDocumentLoadSuccess} options={options}>
                <Carousel interval={null}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <Carousel.Item>
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Document>*/}

              <Carousel interval={null} controls={true} indicators={false}>
                { pdf.map(item => (
                  <Carousel.Item>
                    <img src={ item } style={ isMobile ? { width: '100%' } : { width: '90%' } }/>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>

            {/*<Table responsive>
              <thead>
                <tr>
                  {Array.from({ length: 12 }).map((_, index) => (
                    <th className="table-header" key={index}>Table heading</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {Array.from({ length: 12 }).map((_, index) => (
                    <td className="table-body" key={index}>Table cell {index}</td>
                  ))}
                </tr>
              </tbody>
                  </Table>*/}

          </Row>
        </Container>

      </div>
      <div className='footer'>
          <Container>
            <Row>
              <Col md={ 2 }>
                <img className="footer-logo" src={ logo_start } />
                <br/>
              </Col>
              <Col md={ 2 }>
                <div className={ isMobile ? 'footer-title align-center' : 'footer-title align-left' }>
                  LEGAL
                </div>
                <br/>
                <div className={ isMobile ? 'footer-item align-center' : 'footer-item align-left' } onClick={ () => setShowPrivacy(true) }>
                  Aviso de privacidad
                </div>
                <br/>
              </Col>
              <Col md={ 3 }>
                <div className={ isMobile ? 'footer-title align-center' : 'footer-title align-left' } >
                  CONTACTO
                </div>
                <br/>
                <div className={ isMobile ? 'mb-1 align-center' : 'mb-1 align-left' } style={{ cursor: 'pointer' }}  onClick={ () =>  window.open("mailto:hola@vanguardiade.com") }>
                  <EnvelopeFill className="contact-info align-left c-white"/>&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="contact-info align-left c-white">
                    hola@vanguardiade.com
                  </span>
                </div>
                <div className={ isMobile ? 'mb-1 align-center' : 'mb-1 align-left' } style={{ cursor: 'pointer' }} onClick={ () =>  window.open("https://wa.me/5215568657735") }>
                  <Whatsapp className="contact-info align-left c-white"/>&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="contact-info align-left c-white">
                    55 6865 7735
                  </span>
                </div>
                {/*<div className='mb-1 align-left' style={{ cursor: 'pointer' }} onClick={ () =>  window.open("tel:5570958710") }>
                  <TelephoneFill className="contact-info align-left c-white"/>&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="contact-info align-left c-white">
                    55 7095 8710
                  </span>
                </div>*/}
                {/*<div className='mb-1 align-left' style={{ cursor: 'pointer' }} onClick={ () =>  window.open("https://wa.me/5215555555555") }>
                  <GeoAltFill className="contact-info align-left c-white"/>&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="contact-info align-left c-white">
                    Dirección 1 Colonia Prueba, Estado de México.
                  </span>
                </div>*/}
                <br/>
                <br/>
                
                <div>
                  <div className={ isMobile ? 'footer-title align-center' : 'footer-title align-left' }>REDES SOCIALES</div>
                  <br/>
                  <div className={ isMobile ? 'align-center' : 'align-left' }>
                    <Facebook className="footer-icons" onClick={ () =>  window.open(faceLink) } />&nbsp;&nbsp;&nbsp;&nbsp;
                    <Instagram className="footer-icons" onClick={ () =>  window.open(instaLink) } />&nbsp;&nbsp;
                  </div>
                </div>
                <br/>
              </Col>
              <Col md={ 5 }>
                <div>
                  <div className={ isMobile ? 'footer-title align-center' : 'footer-title align-left' }>CONVIÉRTETE EN UN DEFENSOR DE LOS DERECHOS HUMANOS</div>
                  <br/>
                  <Form id="contactForm" onSubmit={handleOnClick}>
                    <Form.Group className='mb-3'>
                      <Form.Label className={ isMobile ? "label-custom align-center" : "label-custom align-left" }>Déjanos tu correo electrónico y te compartiremos toda la información</Form.Label>
                      <Form.Control required type="email" value={email} name="email" onChange={ (e) => setEmail(e.target.value) } />
                    </Form.Group>

                    <div align={ isMobile ? "center" : 'left' }>
                      <Button variant="outline-primary" type="submit">
                        ENVIAR
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
      </div>

      <Modal show={showPrivacy} onHide={ () => setShowPrivacy(false) } size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton className="modal-service-header"></Modal.Header>
        <Modal.Body className="modal-service-body">
          <br/>
          <div className="modal-service-body-text">
            <div className="policy-title modal-title-style">AVISO DE PRIVACIDAD PARA CLIENTES</div>
            <br/>
            <div className="policy-desc">
              De conformidad con lo establecido en el Artículo 17, fracción II de la Ley Federal de Protección de Datos Personales en posesión de los particulares le informamos que DPA DIGITAL MARKETING S DE RL DE CV (el "Responsable"), con domicilio en Av. Cerezos #66 San Juan Ixtacala, Atizapán de
              Zaragoza, C.P. 52928 Estado de México y portal de internet https://digital-eureka.com/ Tratará los datos personales que recabe de usted en su carácter de cliente y/o usuario, mismos que serán utilizados para identificarlo con las siguientes finalidades:
              Enviarle promociones e información generada por el "Responsable".<br/>
              Para fines estadísticos, publicitarios, promocionales, mercadológicos o de prospección comercial.<br/>
              Información sobre los servicios que el "Responsable" le proporciona.<br/>
              Enviarle facturas o alguna otra información derivada de la relación comercial.<br/>
              Atender y dar seguimiento a quejas.<br/>
              Llevar un récord de contrataciones por parte de su compañía de alguno de los servicios que ofrece el "Responsable".<br/>
              Por lo tanto y para alcanzar las finalidades antes expuestas, se tratarán los siguientes datos personales: nombre, teléfono y correo electrónico.
              <br/><br/>
              <b>Sistemas utilizamos para la captura de estos datos</b>
              <br/>
              1) Formularios de suscripción a contenidos: dentro de la web https://digital-eureka.com/ existen varios formularios para activar la suscripción  con la finalidad de enviar campañas de marketing por correo electrónico, gestión de suscripciones y envío de boletines o noticias.
              2) Formulario de comentarios: La web incluye un formulario cuya finalidad es comentar los artículos y dar su opinión respetando la libertad de expresión. El usuario podrá publicar comentarios en los post que se publiquen. Los datos personales introducidos en el formulario para insertar estos comentarios serán utilizados exclusivamente para moderarlos y publicarlos,
              recabando igualmente estos datos que se almacenarán en los servidores de Godaddy.
              3) Formulario de contacto: Existe un formulario de contacto cuya finalidad es la respuesta de consultas, sugerencias o contacto profesional. En este caso se utilizará la dirección de correo electrónico para responder a las mismas y enviar la información que el usuario requiera a través de la web, estos datos se almacenarán en los servidores de Godaddy.
              4) Cookies: Cuando el usuario se registra o navega en esta web, se almacenan “cookies”, El usuario puede consultar en cualquier momento la política de cookies para ampliar información sobre el uso de cookies y como desactivarlas.
              6) Los usuarios podrán darse de baja en cualquier momento de los servicios prestados por https://digital-eureka.com/ desde la misma Newsletter a través de un formulario de cancelación.
              7) Sistemas de rastreo utilizadas en este sitio: Google (Analytics), en https://digital-eureka.com/ también se estudian las preferencias de sus usuarios, sus características demográficas, sus patrones de tráfico, y otra información en conjunto para comprender mejor quiénes constituyen su audiencia y qué es lo que esta necesita.
              <br/><br/>
              El rastreo de las preferencias de nuestros usuarios también nos ayuda a mostrarle los avisos publicitarios más relevantes.
              <br/><br/>
              Asimismo, informamos que para la captación de suscriptores y clientes utilizamos Facebook Ads teniendo instalado el correspondiente Pixel de Facebook, por lo que al generar un anuncio, se puede segmentar el público por el lugar, datos demográficos, intereses, etc por lo que los datos obtenidos por esta plataforma, estarían sujetos a esta política de privacidad desde el momento en que el usuario deja sus datos para unirse al boletín de https://digital-eureka.com/
              <br/><br/>
              <b>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</b>
              Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del
              uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de
              que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases
              de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse
              al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos
              ARCO.
              <br/><br/>
              Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del
              siguiente medio:
              <br/>
              Enviando un correo electrónico a info@digital-eureka.com

              El responsable no vende ni venderá datos personales de los usuarios a otras empresas ni terceros.
              El responsable compartirá con terceros los datos que posee, solo para cumplir con los servicios contratados por algún usuario, enviar la newsletter, cumplir con exigencias legales o para la administración del sitio web. A este efecto, se proporcionarán los debidos acuerdos de confidencialidad entre las partes.
              Los enlaces a terceros que se puedan encontrar en el sitio web poseen políticas de privacidad ajenas a el responsable. El acceso a estos sitios deberá ser responsabilidad del usuario, siendo su responsabilidad conocerlas y su decisión aceptarlas o no.
              Los datos de contacto de la persona o departamento de datos personales, que está cargo de dar trámite a las solicitudes de derechos ARCO (GDPR), son los siguientes:
              a) Nombre de la persona o departamento de datos personales: Pablo Monroy Espejelb) Domicilio: Av Cerezos #66 San Juan Ixtacala, Atizapán de Zaragoza, Estado de México.
              c) Correo electrónico: info@digital-eureka.com
              <br/><br/>
              ¿Cómo puede limitar el uso o divulgación de su información personal?
              Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios: Enviar un correo a info@digital-eureka.com
              <br/><br/>
              El uso de tecnologías de rastreo en nuestro portal de internet. Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, asÌ como brindarle un mejor servicio y experiencia al navegar en nuestra página.
              Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para los siguientes fines: Ofrecer servicios y productos relevantes para el usuario. Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes: Identificadores, nombre de usuario y contraseñas de una sesión, Idioma preferido por el usuario, región en la que se encuentra el usuario. Tipo de navegador del usuario, Tipo de sistema operativo del usuario, Fecha y hora del inicio y final de una sesión de un usuario, Páginas web visitadas por un usuario, Búsquedas realizadas por un usuario, Publicidad revisada por un usuario, Listas y hábitos de consumo en páginas de compras.
              <br/><br/>
              En relación con la gestión de tus datos asociados a los perfiles sociales de El Responsable, el ejercicio del derecho de acceso, dependerá de la funcionalidad de la red social y las posibilidades de acceso a la información de los perfiles de los usuarios. Con relación a los derechos de acceso y rectificación, le recomendamos que sólo podrá satisfacerse en relación a aquella información que se encuentre bajo el control de El Responsable Además podrá dejar de interactuar, seguir o recibir información de los perfiles sociales de El Responsable, eliminar los contenidos que dejen de interesarle o restringir con quien comparte sus conexiones, mediante los mecanismos estipulados en las diferentes redes sociales.
              <br/><br/>
              <b>¿Cómo puede conocer los cambios en este aviso de privacidad?</b>
              El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
              <br/><br/>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: correo electrónico a info@digital-eureka.com.
              <br/><br/>El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente: se actualizará la sección de aviso de privacidad en la página web.
            </div>
          </div>
        </Modal.Body>
      </Modal>
      
    </div>
  );
}

export default App;
